import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import SideNav from './sidenav';
import MainSupportContent from './MainSupportContent';

function SupportPage() {
  const location = useLocation();
  const initialSection = localStorage.getItem('activeSection') || location.state?.activeSection || 'contactUs';
  const [activeSection, setActiveSection] = useState(initialSection);

  useEffect(() => {
    localStorage.setItem('activeSection', activeSection);
  }, [activeSection]);

  return (
    <Container id="support-index-page-container">
      <MainContent id="support-index-main-content">
        <SideNav
          id="support-index-sidenav"
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        <ContentArea id="support-index-content-area">
          <MainSupportContent
            id="support-index-sepy-category"
            activeSection={activeSection}
          />
        </ContentArea>
      </MainContent>
    </Container>
  );
}

const Container = styled.div`  
  display: flex;  
  width: 100%;  
`;

const MainContent = styled.div`  
  display: flex;  
  flex-direction: row;  
  width: 100%;  
`;

const ContentArea = styled.div`  
  margin-left: 0px;
  width: calc(100% - 242px);
`;

export default SupportPage;