import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import CloseButton from '../../assets/closeicon24px.svg';
import { reopenPlanAPI } from '../../services/endpoints';

const ReopenPlansModal = ({ isOpen, onClose, plans }) => {

  const modalRef = useRef();

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  const handleConfirmClick = async () => {  
    try {  
      const reopenPromises = plans.map(plan => reopenPlanAPI(plan.name));  
      const responses = await Promise.all(reopenPromises);  
      console.log('Plans submitted successfully:', responses);  
      onClose();  
      window.location.reload();  
    } catch (error) {  
      console.error('Error submitting plans:', error);  
    }  
  };

  return (
    <ModalContainer id="multiple-modal-container">
      <ModalContent id="multiple-modal-content" ref={modalRef}>
        <ModalHeader id="multiple-modal-header">
          <ModalTitle id="multiple-modal-title">Are you sure you want to reopen the following plans?</ModalTitle>
          <CloseIcon id="multiple-modal-close-icon" src={CloseButton} alt="Close" onClick={onClose} />
        </ModalHeader>
        <ModalBody id="multiple-modal-body">
          <p id="multiple-modal-body-text">Reopening a plan will allow you to view all changes and make additional updates. Reopened plans will be viewable on the home page once they finish processing.</p>
          <PlanList id="multiple-plan-list">
            {plans.map((plan) => (
              <PlanListItem id={`multiple-plan-list-item-${plan.id}`} key={plan.id}>{plan.name}</PlanListItem>
            ))}
          </PlanList>
        </ModalBody>
        <ModalFooter id="multiple-modal-footer">
          <CancelButton id="multiple-cancel-button" onClick={onClose}>No, go back</CancelButton>
          <ConfirmButton id="multiple-confirm-button" onClick={handleConfirmClick}>Yes, reopen these plans</ConfirmButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`  
  position: fixed;  
  top: 0;  
  left: 0;  
  width: 100%;  
  height: 100%;  
  background: rgba(0, 0, 0, 0.5);  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  z-index: 60;
`;

const ModalContent = styled.div`  
  background: #fff;  
  padding: 24px 24px 24px 24px;
  border-radius: 4px;  
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);  
  width: 735px;  
  max-width: 784px;  
  position: relative;
  max-height: 95vh;
  overflow: auto;
`;

const ModalHeader = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
`;

const ModalTitle = styled.h2`  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 25.63px;  
  font-weight: 700;  
  line-height: 32px;
  color: #002677;  
`;

const CloseIcon = styled.img`  
  position: absolute;  
  top: 24px;
  right: 24px;
  cursor: pointer;  
`;

const ModalBody = styled.div`  
  margin-top: 16px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-size: 16px;  
  line-height: 20px;
  color: #323334;  
`;

const ModalFooter = styled.div`  
  display: flex;  
  justify-content: flex-end;  
  margin-top: 32px;  
  gap: 16px;  
`;

const CancelButton = styled.button`  
  padding: 10px 24px;  
  background: #ffffff;  
  border: 1px solid #002677;  
  border-radius: 24px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px;
  line-height: 20px;  
  color: #002677;  
  cursor: pointer;  

  &:hover {
    border-color: #001D5B;
    color: #001D5B;
    background-color: #F3F3F3;
  }
`;

const ConfirmButton = styled.button`  
  padding: 10px 24px;  
  background: #002677;  
  border: none;  
  border-radius: 24px;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
  font-weight: 700;  
  font-size: 16px; 
  line-height: 20px; 
  color: #ffffff;  
  cursor: pointer;  

  &:hover {
    color: #FFF;
    border-color: #FFF;
    background-color: #001D5B;
  }
`;

const PlanList = styled.ul`  
  margin-left: 20px;
  margin-top: 24px;
`;

const PlanListItem = styled.li`  
  font-weight: bold;  
  line-height: 20px;
`;

export default ReopenPlansModal;