import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoginImg from '../../assets/loginpageimg.svg';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const LoginPage = ({ onLogin }) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const tokenGenerator = async () => {
        if (accounts.length > 0) {
            const request = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            };
            try {
                const tokenResponse = await instance.acquireTokenSilent(request);
                console.log("tokenResponse", tokenResponse);
                sessionStorage.setItem('accessToken', tokenResponse.accessToken);
            } catch (error) {
                console.error("Silent token acquisition failed, acquiring token via redirect:", error);
                instance.acquireTokenRedirect(request);
            }
        }
    };

    useEffect(() => {
        if (accounts.length > 0) {
            onLogin();
            tokenGenerator();
            console.log("accounts", accounts);
        }
    }, [accounts, onLogin]);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(error => {
            console.error('Login failed:', error);
        });
    };

    return (
        <LoginContainer id="login-container">
            <Content id="content">
                <TextContainer id="text-container">
                    <WelcomeText id="welcome-text">Welcome</WelcomeText>
                    <DescriptionText id="description-text">
                        Login to access the benefits configuration tool.
                    </DescriptionText>
                </TextContainer>
                {!isAuthenticated && (
                    <LoginButton id="login-button" onClick={handleLogin}>
                        Log in with One Healthcare ID
                    </LoginButton>
                )}
            </Content>
            <ImageWrapper id="image-wrapper">
                <StyledImage id="styled-image" src={LoginImg} alt="Login" />
            </ImageWrapper>
        </LoginContainer>
    );
};

const LoginContainer = styled.div`  
    display: flex;  
    height: 71vh;  
    width: 100%;  
    position: relative;
`;

const Content = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;
    width: 451px;  
    height: 208px;  
    position: absolute;  
    left: 44px;  
    top: 78px;
`;

const TextContainer = styled.div`  
    display: flex;  
    flex-direction: column;  
    align-items: flex-start;  
    padding: 0px;  
    width: 100%;  
`;

const WelcomeText = styled.h1`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 46.17px;  
    line-height: 56px;  
    color: #002677;  
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const DescriptionText = styled.p`  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 400;  
    font-size: 18px;  
    line-height: 24px;  
    color: #323334;  
    width: 100%;    
`;

const LoginButton = styled.button`  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    padding: 12px 24px;  
    width: 303px;  
    height: 48px;  
    background: #002677;  
    border: 1px solid #002677;  
    border-radius: 999px;  
    font-family: "Enterprise Sans VF", Helvetica, Arial;  
    font-style: normal;  
    font-weight: 700;  
    font-size: 18px;  
    line-height: 24px;  
    color: #FFFFFF;  
    cursor: pointer;  
    margin-top: 44px;  
    white-space: nowrap;
    &:hover {  
        background: #001D5B;  
        border: 1px solid #001D5B;
    }  
    &:active {  
        background: #001533;  
    }  
`;

const ImageWrapper = styled.div`  
  position: fixed;  
  top: 111px;
  right: 0;  
  bottom: 44px;
  display: flex;  
  width: 52.7%;
  border-bottom-left-radius: 206px;  
  justify-content: center;  
  align-items: center;  
  background-color: #D9F6FA;  
  z-index: 1;  
`;



const StyledImage = styled.img`  
    height: 80%;  
    width: 70%;  
`;

export default LoginPage;