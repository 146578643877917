import Header from './widgets/header';
import Footer from './widgets/footer';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import ProcessingPage from './ProcessingPage';
import QueuePage from './QueuePage';
import SubmittedPage from './SubmittedPage';
import SelectionPage from './SelectionPage';
import AnalysisPage from './AnalysisPage';
import LoginPage from './LoginPage/index';
import SupportLandingPage from './SupportPage/LandingPage';
import SupportPage from './SupportPage/index';
import styled from 'styled-components';
import '../fonts/OptumSans.css';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => { navigate('/queue'); };

  const isAnalysisPage = location.pathname === '/queue/selection/analysis';

  return (
    <MsalProvider instance={msalInstance}>
      <Root id="app-root">
        <Header id="app-header" location={location} />
        <Content id="app-content" noPadding={isAnalysisPage}>
          <Routes id="app-routes">
            <Route path="/" element={<LoginPage id="login-page" onLogin={handleLogin} />} />
            <Route path="/queue" element={<QueuePage id="queue-page" />} />
            <Route path="/processing" element={<ProcessingPage id="processing-page" />} />
            <Route path="/submitted" element={<SubmittedPage id="submitted-page" />} />
            <Route path="/queue/selection" element={<SelectionPage id="selection-page" />} />
            <Route path="/queue/selection/analysis" element={<AnalysisPage id="analysis-page" />} />
            <Route path="/support" element={<SupportLandingPage id="landing-support-page" />} />
            <Route path="/support/contact-and-feedback" element={<SupportPage id="support-page" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Content>
        <Footer id="app-footer" />
      </Root>
    </MsalProvider>
  );
}

const Root = styled.div`  
  display: flex;  
  flex-direction: column;  
  min-height: 100vh;  
  font-family: "Enterprise Sans VF", Helvetica, Arial;  
`;

const Content = styled.div`  
  flex: 1;  
  padding: ${(props) => (props.noPadding ? '0' : '20px')};  
  margin-top: 111px;  
  overflow: auto;  
`;

export default App;  
