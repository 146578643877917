import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const fetchQueueData = async (callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.get(`${BASE_URL}/products`);
        callback(response.data);
    } catch (error) {
        console.error('Error fetching Queue data:', error);
        callback(error);
    }
};

const fetchProcessingData = async (callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.get(`${BASE_URL}/products/processing`);
        callback(response.data);
    } catch (error) {
        console.error('Error fetching Processing data:', error);
        callback(error);
    }
};

const fetchSubmittedData = async (callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.get(`${BASE_URL}/products/submitted`);
        callback(response.data);
    } catch (error) {
        console.error('Error fetching Submitted data:', error);
        callback(error);
    }
};

const fetchDeltaData = async (productId, callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.post(`${BASE_URL}/products/${productId}/services/delta`, {
            sort_by: "service_id",
            sort_order: "ASC",
            page_size: 1000,
            page: 1
        });
        callback(response.data);
    } catch (error) {
        console.error("Error fetching delta data", error);
        callback(error);
    }
};

const fetchPDFUrl = async (productId, previousYear) => {
    try {
        const response = await axios.get(`${BASE_URL}/${productId}/eoc_pdf`, {
            params: { previous_year: previousYear }
        });
        return response.data.data.pdfurl;
    } catch (error) {
        console.error("Error fetching PDF URL", error);
        throw error;
    }
};

const fetchSeseRules = async (callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.get(`${BASE_URL}/facets/cmc_sese_service/sese_rules`);
        callback(response.data);
    } catch (error) {
        console.error('Error fetching SESE rules:', error);
        callback(error);
    }
};

const approveChangesAPI = async (productId, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/${productId}/service_rules/approve`, data);
        return response.data;
    } catch (error) {
        console.error("Error approving changes:", error);
        throw error;
    }
};

const discardChangesAPI = async (productId, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/${productId}/service_rules/discard`, data);
        return response.data;
    } catch (error) {
        console.error("Error discarding changes:", error);
        throw error;
    }
};

const submitPlanAPI = async (productId) => {
    try {
        const response = await axios.post(`${BASE_URL}/${productId}/submit`);
        return response.data;
    } catch (error) {
        console.error("Error submitting plan:", error);
        throw error;
    }
};

const reopenPlanAPI = async (productId) => {
    try {
        const response = await axios.post(`${BASE_URL}/${productId}/reopen`);
        return response.data;
    } catch (error) {
        console.error("Error reopening plan:", error);
        throw error;
    }
};

const fetchRelatedServices = async (relId, callback) => {
    callback = (callback instanceof Function) ? callback : () => { };
    try {
        const response = await axios.get(`${BASE_URL}/related_services`, {
            params: { rel_id: relId }
        });
        callback(response.data);
    } catch (error) {
        console.error('Error fetching related services:', error);
        callback(error);
    }
};

const submitFeedback = async (feedbackData) => {
    try {
        const response = await axios.put(`${BASE_URL}/feedback`, feedbackData);
        return response.data;
    } catch (error) {
        console.error("Error submitting feedback:", error);
        throw error;
    }
};

export {
    fetchQueueData,
    fetchProcessingData,
    fetchSubmittedData,
    fetchDeltaData,
    fetchPDFUrl,
    fetchSeseRules,
    approveChangesAPI,
    discardChangesAPI,
    submitPlanAPI,
    reopenPlanAPI,
    fetchRelatedServices,
    submitFeedback
};